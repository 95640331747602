

































import { defineComponent } from '@vue/composition-api';
import CookieLaw from 'vue-cookie-law';

export default defineComponent({
  components: { CookieLaw },
  setup() {
    return {
      policyUrl: process.env.VUE_APP_POLICY_URL,
    };
  },
});
